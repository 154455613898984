
$(document).on('turbolinks:load', function(){
    show_based_on_type()
    show_request_action()
    show_assembly_action()
    $("#request_decision").change(show_request_action)
    $("#assembly_decision").change(show_assembly_action)
    $("#request_decision").change(function() {
        $(".motive_field").find("input[type!='hidden'], select, textarea").val("");
    });
    $("#assembly_decision").change(function() {
        $(".motive_field").find("input[type!='hidden'], select, textarea").val("");
    });
    $(".show_delete").first().addClass("d-none")
    $("#decision_type").change(show_based_on_type)

    //LIMPIAR CUAMDNO SE CAMBIA DE TIPO
    $("#decision_type").on('change', function(){
        $(".change_value").find("input[type!='hidden'], select, textarea").val("");
        $(".change_all_values").find("input[type!='hidden'], select, textarea").val("");
    })
});

function show_based_on_type(){
    if ($("#decision_type option:selected").val() === "3"){
        $(".change_stocks").hide()
        $(".change_all_values").hide()
        $(".change_nominal_value").show()
        $(".show_merged_company").hide()
    }
    else if ($("#decision_type option:selected").val() === "4"){
        $(".change_stocks").show()
        $(".change_all_values").hide()
        $(".change_nominal_value").hide()
        $(".show_merged_company").hide()
    }
    else if($("#decision_type option:selected").val() === "5"){
        $(".change_stocks").show()
        $(".change_all_values").show()
        $(".change_nominal_value").hide()
        $(".show_merged_company").hide()

    }
    else if ($("#decision_type option:selected").val() === "1"){
        $(".show_merged_company").show()
        $(".change_stocks").show()
        $(".change_nominal_value").hide()
        $(".change_all_values").show()

    }
    else{
        $(".change_stocks").hide()
        $(".change_all_values").hide()
        $(".change_nominal_value").hide()
        $(".show_merged_company").hide()
    }
}

function show_request_action() {
    // LIMPIA EL CAMPO DE MOTIVO DE LA SOLICITUD CUANDO SE CAMBIA DE DECISIÓN
    $("#request_motive").val("");

    const decision = $("#request_decision option:selected").val();
    const actions = {
        "Aprobar": {
            show: ["#approve_request"],
            hide: ["#modify_params", "#validate_request", "#refuse_request", "#assembly_request_motive", "#enable_request", "#update_document"],
            disable: ["#disable_input_file"],
            setValue: () => $("#disable_input_file").val("")
        },
        "Generar": {
            show: ["#validate_request"],
            hide: ["#modify_params", "#approve_request", "#refuse_request", "#assembly_request_motive", "#enable_request", "#update_document"],
            disable: ["#authorized_field"]
        },
        "Actualizar": {
            show: ["#update_document"],
            hide: ["#modify_params", "#validate_request", "#approve_request", "#refuse_request", "#enable_request", "#assembly_request_motive"],
            enable: ["#disable_input_file"]
        },
        "Modificar Parámetros": {
            show: ["#modify_params"],
            hide: ["#validate_request", "#approve_request", "#refuse_request", "#assembly_request_motive", "#enable_request", "#update_document"],
            enable: ["#authorized_field"]
        },
        "Rechazar": {
            show: ["#refuse_request", "#assembly_request_motive"],
            hide: ["#modify_params", "#validate_request", "#approve_request", "#update_request", "#enable_request", "#update_document"],
            disable: ["#disable_input_file"],
            setValue: () => $("#disable_input_file").val("")
        },
        "Habilitar": {
            show: ["#enable_request"],
            hide: ["#modify_params", "#validate_request", "#approve_request", "#update_request", "#refuse_request", "#assembly_request_motive", "#update_document"],
            disable: ["#authorized_field"]
        },
        "": {
            hide: ["#modify_params", "#validate_request", "#approve_request", "#update_request", "#refuse_request", "#enable_request", "#update_document", "#assembly_request_motive"],
            disable: ["#disable_input_file"]
        }
    };

    $.each(actions[decision], (key, value) => {
        if (key === "show") {
            $.each(value, (index, selector) => $(selector).show());
        } else if (key === "hide") {
            $.each(value, (index, selector) => $(selector).hide());
        } else if (key === "enable") {
            $.each(value, (index, selector) => $(selector).attr("disabled", false));
        } else if (key === "disable") {
            $.each(value, (index, selector) => $(selector).attr("disabled", true));
        } else if (key === "setValue") {
            value();
        }
    });
}

function show_assembly_action(){
    const decision = $("#assembly_decision option:selected").val();
    const actions = {
        "Cancelar": {
            show: ["#cancel_assembly", "#assembly_motive"],
            hide: ["#update_assembly"],
            disable: [".disable_input_file", "#assembly_date", ".assembly_hour", ".assembly_request_date_limit", ".assembly_request_hour_limit"],
            setValue: () => {
                $("#assembly_date").val($(".assembly_date_was").val()).trigger("change");
                $("#assembly_hour_4i").val($(".assembly_hour_was").val()).trigger("change");
                $("#assembly_hour_5i").val($(".assembly_min_was").val()).trigger("change");
                $("#assembly_assembly_request_date_limit").val($(".assembly_request_date_was").val()).trigger("change");
                $("#assembly_assembly_request_hour_limit_4i").val($(".assembly_request_hour_was").val()).trigger("change");
                $("#assembly_assembly_request_hour_limit_5i").val($(".assembly_request_min_was").val()).trigger("change");
                $(".disable_input_file").val("");
            }

        },
        "Actualizar": {
            show: ["#update_assembly"],
            hide: ["#cancel_assembly", "#assembly_motive"],
            enable: [".disable_input_file", "#assembly_date", ".assembly_hour", ".assembly_request_date_limit", ".assembly_request_hour_limit"],
        },
        "": {
            hide: ["#cancel_assembly", "#update_assembly", "#assembly_motive"],
            disable: ["#assembly_date", ".assembly_hour"]
        }
    };

    $.each(actions[decision], (key, value) => {
        if (key === "show") {
            $.each(value, (index, selector) => $(selector).show());
        } else if (key === "hide") {
            $.each(value, (index, selector) => $(selector).hide());
        } else if (key === "enable") {
            $.each(value, (index, selector) => $(selector).attr("disabled", false));
        } else if (key === "disable") {
            $.each(value, (index, selector) => $(selector).attr("disabled", true));
        } else if (key === "setValue") {
            value();
        }
    });
}