$(document).on("turbolinks:load", function () {
    let international_fields = $(".international");
    let national_fields = $(".national");
    let bank_country= $(".bank_country");
    ShowCorrespondentFields()
    $("#show_correspondent_fields").on("change", ShowCorrespondentFields);
    //CREA UNA FUNCION QUE SOLO PERMITA INGRESAR NUMEROS
    function onlyNumbers(e) {
        var key = e.charCode || e.keyCode || 0;
        return ( key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) );
    }
    //VALIDA QUE SOLO SE INGRESEN NUMEROS
    $('.number_account').on('keypress', onlyNumbers);
    
    // Initial state based on bank_type value
    const initialBankType = bank_country.val();
    if (initialBankType === "Venezuela") {
        national_fields.show();
        international_fields.hide();
    } 
    else if (initialBankType === "Internacional") {
        national_fields.hide();
        international_fields.show();
    }
    else
    {
        national_fields.hide()
    }
  
    // Change event handler (remains the same)
    bank_country.on("change", function (e) {
        const selectedValue = $(this).val();

        // Clear both sets of fields initially  
        national_fields.find("input[type!='hidden'], select, textarea").val("");
        international_fields.find("input[type!='hidden'], select, textarea").val("");

        if (selectedValue === "Venezuela") {
            national_fields.show();
            international_fields.hide();
        } else if (selectedValue === "Internacional") {
            national_fields.hide();
            international_fields.show();
        }else {
            national_fields.hide()
            international_fields.show();
        }
    });
});


const ShowCorrespondentFields = () => {
    let show_correspondent_value = $("#show_correspondent_fields").val();
    if (show_correspondent_value == "true") {
        $(".show_correspondent").show();
    } else {
        $(".show_correspondent").hide();
    }
}